<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm9 md10 text-left>
        <span class="mainHeader">Returned Bill History</span>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right>
        <v-text-field
          background-color="white"
          v-model="keyword"
          color="#b234a9"
          clearable
          hide-details
          placeholder="Search Bill"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
     
      <v-flex xs12 py-6 text-left>
        <v-card
          class="rounded-lg"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex xs12 v-if="dataList">
              <template>
  <v-data-table
    :headers="Headers"
    :items="dataList"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="_id._id"
    show-expand
    class="elevation-0"
  >
  <template #item._id.create_date="{ item }">
    {{ formatDate(item._id.create_date) }}
  </template>
    <template v-slot:top>
      <v-toolbar flat class="px-0 mx-0">
        <v-toolbar-title>Expandable Table</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-switch
          v-model="singleExpand"
          label="Single expand"
          class="mt-2"
        ></v-switch> -->
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="px-0">
   <!-- <v-card class="pa-4" elevation="0"> -->
    <v-layout wrap pa-2>
      <v-flex xs12 py-1 pl-4 class="subhed">
        <span>Item Details:</span>
      </v-flex>
      <v-flex xs12>
        <v-simple-table>
        <thead>
          <tr>
                            <th class="text-left "><b>S.No.</b></th>
                            <th class="text-left ">
                              <b>Item</b>
                            </th>
                            <th class="text-left "><b>Rate(₹)</b></th>
                            <!-- <th class="text-left ">
                              <b>Qty</b>
                            </th> -->
                            <th class="text-left "><b>Gr.wt(gm)</b></th>
                            <th class="text-left "><b>St.wt(gm)</b></th>
                            <th class="text-left "><b>Net.wt(gm)</b></th>
                            <th class="text-left "><b>Melt(%)</b></th>
                            <th class="text-left "><b>Amount(₹)</b></th>
                            <!-- <th class="text-left subhed"><b>Action</b></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in item.items" :key="i" class="table">
                            
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.itemName"
                                >{{ value.itemName }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.goldRate">
                                {{ value.goldRate}}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.quantity">{{
                                value.quantity
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.melt">{{
                                value.melt
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.itemAmount">{{
                                value.itemAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <!-- <td>
                              <v-icon
                                size="18"
                                class="pr-2"
                                color="#3F053C"
                                style="cursor: pointer"
                                @click="
                                  (curitem = value),
                                    (editdialogue = true)
                                "
                                >mdi-pencil</v-icon
                              >
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (curId = value._id)"
                                >mdi-delete-outline</v-icon
                              >
                            </td>  -->
                          </tr>
                        </tbody>
                    </v-simple-table>
      </v-flex>
    </v-layout>
  
   <!-- </v-card> -->
   </td>
    </template>
  </v-data-table>
</template>
            </v-flex>
          </v-layout>
     
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      // Pagelength: null,
      dataList:[],
      keyword:null,
    juwellaryId: localStorage.getItem("juwellaryId"),
        // expanded: [],
        // singleExpand: false,
        Headers: [
          {
            text: 'Bill.No',
            align: 'start',
            sortable: false,
            value: '_id.billNo',
          },
          { text: 'Date', value: '_id.create_date' },
          { text: 'Customer Name', value: '_id.customerName' },
          { text: 'Contact', value: '_id.mobileNumber' },
          { text: 'Net.Wt(gm)', value: '_id.oldGoldNetWeight' },
          { text: 'Total(₹)', value: '_id.oldGoldAmount' },
          { text: '', value: 'data-table-expand' },
        ],
        expanded: [],
        singleExpand: true,
      
    
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    keyword() {
      this.getData();
    },
  },
  methods: {
   
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/return/getalllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          count: 20,
        jewelleryId: this.juwellaryId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dataList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);

          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
    var dt = new Date(item);
    var day = dt.getDate();
    var year = dt.getFullYear();
    // var hours = dt.getHours();
    // var minutes = dt.getMinutes();
    dt = dt.toString();
    // var ampm = hours >= 12 ? "pm" : "am";
    // hours = hours % 12;
    // hours = hours ? hours : 12;
    // minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = day + " " + dt.slice(4, 7) + " " + year;
    //  +
    // " , " +
    // hours +
    // ":" +
    // minutes +
    // " " +
    // ampm;

    return strTime;
  },
  },
};
</script>
<style scoped>
.v-text-field input {
  font-family: kumbhBold;
  }
</style>
